// JS
import '/src/js/core/credits.js';
import '/src/js/core/general.js';
//import '/src/js/components/dynamic-header.js';

// Bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// CSS
import '/src/sass/main.scss';
